<template>
  <div id="home">
    <Navbar></Navbar>

    <main
      class=" relative overflow-hidden h-screen pb-4 z-10 bg-gradient-to-tl from-transparent via-slate-900 to-black"
    >
      <div class=" flex relative z-20 items-center overflow-hidden">
        <div class="container mx-auto px-6 flex relative py-16">
          <div
            class="sm:w-2/3 lg:w-4/5 flex flex-col relative z-20 mt-16 fade-in-top"
          >
            <!-- <span class="w-80 md:w-9/12 h-2 bg-gray-100 gradientBg dark:bg-white mb-4 fade-in-left1"></span> -->
            <h1
              class="title text-6xl md:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-100 mt-8 md:max-w-3xl"
            >
              <span class="">
                BUILDING SOFTWARE OF THE FUTURE.
              </span>
            </h1>
            <div class="sm:block sm:w-1/3 lg:w-3/5 relative mt-12">
              <p class="text-3xl font-light text-gray-100 dark:text-white mt-0">
                I work to build the software for the future of education and
                business connecting people and ideas.
              </p>

              <p class="text-2xl font-light text-gray-100 dark:text-white mt-3">
                Let's get in touch:
                <a class="text-green-500" href="mailto:me@tobyroyal.codes"
                  >me@tobyroyal.codes</a
                >.
              </p>

              <p class="text-xl font-light text-gray-100 dark:text-white mt-12">
                Scroll down to check out my work.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>

    <main
      class="overflow-hidden h-fit pb-4 z-10 bg-gradient-to-bl from-transparent via-slate-900 to-black rounded-sm pt-4"
    >
      <div class="flex relative z-20 items-center overflow-hidden">
        <div class="container mx-auto px-6 flex relative py-0">
          <div
            class="flex flex-col relative z-20 pt-12 pb-12 fade-in-top bg-slate-900"
          >
            <!-- <p class="text-lg text-white mb-2">Prepare for a lot of text...</p> -->
            <h1
              class="title text-6xl sm:text-8xl font-black flex flex-col leading-none dark:text-white text-gray-100"
            >
              <span class="">Portfolio</span>
            </h1>

            <p class="sm:text-lg text-gray-100 dark:text-white mt-12">
              <strong class="text-2xl">NOW:</strong>&nbsp;Currently employed as
              an IT Support & Application Specialist at an International School.
            </p>
            <p class="sm:text-sm text-gray-400 mt-0">
              Certified Google Educator (L1) & Technical Support Fundamentals
              and exploring Comptia A+.
            </p>

            <p class="sm:text-lg text-gray-400 dark:text-white mt-4">
              <strong>2021-Present:</strong>&nbsp;Director & Developer at
              [unavailable].
            </p>
            <p class="sm:text-lg text-gray-500 dark:text-white mt-1">
              <strong>2018-Present:</strong>&nbsp;Director of a FiveM aviation
              oriented community, surpassing over 10K members and seeing 5,000+
              unique players a month.
            </p>
            <p class="sm:text-lg text-gray-600 dark:text-white mt-1">
              <strong>2018-2022:</strong>&nbsp;Chief Executive Officer at
              Pontifex Technologies, a small startup hosting company.
            </p>
            <p class="sm:text-lg text-gray-800 dark:text-white mt-1">
              <strong>2015-2022:</strong>&nbsp;Freelance theatre technician.
            </p>

            <div class="mt-8 fade-in-top hidden">
              <router-link
                to="/start"
                class="hidden md:inline-block title uppercase font-bold py-2 px-4 rounded-lg gradientBg border-0 border-transparent text-white text-md mr-4 hover:bg-blue-800 transition duration-600 ease-in-out"
              >
                Get Started&nbsp;&#8594;
              </router-link>
              <router-link
                to="/start"
                class="md:hidden title uppercase font-bold py-2 px-4 rounded-lg gradientBg border-0 border-transparent text-white text-md mr-4 hover:bg-blue-800 transition duration-600 ease-in-out"
              >
                Get Started&nbsp;&#8594;
              </router-link>
              <div class="pt-10 md:hidden"></div>
              <!-- <router-link
                to="/work"
                class="title uppercase font-bold py-2 px-4 md:ml-4 rounded-lg bg-transparent border-2 border-blue-500 text-white dark:text-white hover:bg-blue-800 hover:border-blue-800 hover:text-white text-md transition duration-500 ease-in-out"
              >
                My Work
              </router-link> -->
            </div>
          </div>
          <div class="hidden  md:w-1/3 lg:w-3/5 relative pl-12">
            <div class="flex flex-col relative z-20 mt-12 pb-12 fade-in-top">
              <h1
                class="title text-2xl sm:text-4xl font-black flex flex-col leading-none dark:text-white text-gray-100 mt-4"
              >
                <span class="">Skills:</span>
              </h1>

              <p class="sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>The Web:</strong>&nbsp;PHP, JavaScript (plain, VueJS /
                NuxtJS), HTML, CSS, Wordpress & Typedream.
              </p>
              <p class="sm:text-lg text-gray-400 mt-0">
                <strong>Other notable skills:</strong>&nbsp;Domain Management
                (DNS, Email & Registration), Cloudflare Specialist, Google
                Workspace & Microsoft 365 expert.
              </p>

              <p class="sm:text-lg text-gray-100 dark:text-white mt-4">
                <strong>System Admin:</strong>&nbsp;Linux (Ubuntu, Debian),
                Windows (Server 2012+), Cisco Networking (CLI & Meraki),
                pfSense, VMWare (ESXi / vSphere / Workstation), Proxmox, Git,
                NGINX & Apache, SQL & NoSQL Databases, Bash & PowerShell
                scripting, and more.
              </p>

              <p class="sm:text-lg text-gray-100 dark:text-white mt-4">
                <strong>The Adobe Suite:</strong>&nbsp;Experienced in Premiere
                Pro & After Effects, and familiar with Photoshop & Illustrator.
                Some experience with Audition, Lightroom and InDesign.
              </p>

              <p class="sm:text-lg text-gray-100 dark:text-white mt-4">
                <strong>Theatre Technology:</strong>&nbsp;ETC EOS Lighting
                consoles (NOMAD and Physical), GrandMA 2, Allen & Heath sound
                consoles, Shure wireless microphone systems, VMix vision mixing
                and more.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>

    <main
      class="overflow-hidden h-fit pb-4 z-10 bg-opacity-80 bg-black rounded-sm mt-0 pb-12 pt-12"
    >
      <div class="overflow-hidden">
        <div class="container mx-auto px-6 py-0">
          <div class="md:flex md:space-x-3.5 items-top">
            <h1
              class="item title text-6xl sm:text-8xl font-black flex leading-none dark:text-white text-gray-100 pr-12 vertical-text"
            >
              <span>My Work</span>
            </h1>
            <div
              class="item grid overflow-hidden mt-5 md:mt-0 grid-cols-1 auto-rows-auto md:grid-cols-2 md:auto-rows-auto gap-5 fade-in-top"
            >
              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>BetterPanda v2</strong><br />
                Rebuilt from the ground up, integrating with AssetPanda, JAMF
                and Google APIs.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Google Classroom AI Detection</strong><br />
                AI detection built right into Google Classroom.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>SmartRelay</strong><br />
                Mangement of 30+ smart plugs to ensure maximum power savings.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>RoyalAutomate</strong><br />Custom built software for
                home automation.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Hair & Beauty by KTKelly</strong><br />A website for a
                hair stylist.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>14 Amerbach St.</strong><br />Website designed for an
                American style steakhouse
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Andy Miraka</strong><br />Portfolio website to show-off
                their photography work.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Mural Films</strong><br />Portfolio website to showcase
                various works.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>'Splotify'</strong><br />A tool that utilizes the
                Spotify API to input accurate metadata about a song for DJs.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>'Snotify'</strong><br />Utilizing the Spotify API to
                notify users of the currently playing song in a subtle manner.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>SportX</strong><br />A tool to create and manage YouTube
                livestreams (primarily for sports) and have a one-click solution
                to start a stream.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Vlare (vlare.tv)</strong><br />A video sharing platform
                designed to compete with the big-named platforms. <br /><a
                  class="text-gray-400 text-xs"
                  target="_blank"
                  href="https://dancodes.online?rel=toby"
                  >Platform was developed in combination with DanCodes.</a
                >
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Spotify - OSC - ETC</strong><br />Creating a bridge
                between your current music to your lighting setup via OSC.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>BetterPanda</strong><br />A smart asset management tool
                for easy check in and out of loaner equipment.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>GuestAcross</strong><br />Guest management system
                designed for schools and universities linking with the Veracross
                API.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Zendesk Alerting Service</strong><br />A tool designed
                to show end-users current incidents before a ticket is
                submitted.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Zendesk UI Rework</strong><br />A custom designed
                Zendesk template to improve the user experience and encourage
                the use of the knowledge base.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>PRTG Smart Digital Signage</strong><br />A simple
                program that displays the current status of the infrastructure.
                It attempts to highlight and predict issues before they occur.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>LithiumATC</strong><br />An attempt at creating a
                realistic air traffic control management tool built around the
                FiveM platform.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Hair Design by Barry</strong><br />Originally a
                WordPress site, now converted to Vue for a more modern look and
                feel and blazing fast performance.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>ProjectAero</strong><br />Realistic flight management
                software hand crafted. Built around the FiveM platform.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Streamity</strong><br />A Streamdeck without the
                physical product, allowing you to use your phone as a streamdeck
                to control your stream.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>ClassTab</strong><br />A chrome extension that allows
                students to see their current class schedule and upcoming
                assignments from Google Classroom when they open a new tab.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- theater -->
    <main
      class="overflow-hidden h-fit pb-4 z-10 bg-opacity-90 bg-black rounded-sm mt-0 pb-12 pt-12"
    >
      <div class="overflow-hidden">
        <div class="container mx-auto px-6 py-0">
          <div class="md:flex md:space-x-3.5 items-top">
            <h1
              class="item title text-6xl sm:text-8xl font-black flex leading-none dark:text-white text-gray-100 pr-12 vertical-text"
            >
              <span>Theater</span>
            </h1>
            <div
              class="item grid overflow-hidden mt-5 md:mt-0 grid-cols-1 auto-rows-auto md:grid-cols-2 md:auto-rows-auto gap-5 fade-in-top"
            >
              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Elf - The Musical (2021)</strong><br />I was lead
                technicial director for this production. I was responsible for
                overseeing the lighting and sound, in addition to some
                stage-hand duties.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>My Fair Lady (2019)</strong><br />I designed the
                lighting for this show however during the production I was
                backstage hands and "on-call" for last minute tech issues.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Guys & Dolls (2018)</strong><br />I was 'head of
                lighting' for this production, desiging the lights and running
                the board during the production.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>General Technical Crew (2014-22)</strong><br />Helping
                out with all sorts of small and large productions that I can't
                fit into this list.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Anne Jr. (2015)</strong><br />My first ever production
                and I was lead lighting designer and operator.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Summer/Winter Idols (2017-22)</strong><br />An intense
                tallent show, which I was involved in for 5 years doing
                lighting, sound, video and even DJing.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>TedX Youth (2019)</strong><br />I was involved in the
                video production for this event.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>Prom Night (2019 & 2022)</strong><br />Lead lighting
                designer and operator, having to be flexible with the music to
                create a great atmosphere.
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>High-School Graduation Ceremonies (2016-22)</strong
                ><br />A fast-paced event, with me doing camera operation and
                occasionally vision mixing. I was even involved in my own
                graduation ;)
              </div>

              <div class="box sm:text-lg text-gray-100 dark:text-white mt-0">
                <strong>"Vanya and Sonia and Masha and Spike" (2022)</strong
                ><br />Responsible for sound design and operation.
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>

    <!-- <Promotion></Promotion> -->

    <Footer></Footer>
  </div>
</template>

<script>
  export default {
    name: "Home",
    components: {},
  };
</script>

<!-- <script>
  const txts = document.querySelector(".animate-text").children,
    txtsLen = txts.length;
  let index = 0;
  const textInTimer = 5000,
    textOutTimer = 4800;

  function animateText() {
    for (let i = 0; i < txtsLen; i++) {
      txts[i].classList.remove("text-in", "text-out");
    }
    txts[index].classList.add("text-in");

    setTimeout(function() {
      txts[index].classList.add("text-out");
    }, textOutTimer);

    setTimeout(function() {
      if (index == txtsLen - 1) {
        index = 0;
      } else {
        index++;
      }
      animateText();
    }, textInTimer);
  }
  $(document).ready(function() {
    animateText();
  });
</script> -->

<style>
  .bgNew {
    background-color: #090d16 !important;
  }

  .fade-in-left {
    -webkit-animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1)
      both;
    animation: fade-in-left 0.8s cubic-bezier(0.39, 0.575, 0.565, 1) both;
  }
  .fade-in-left1 {
    -webkit-animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1)
      1.4s both;
    animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) 1.4s both;
  }
  .fade-in-left2 {
    -webkit-animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1)
      0.2s both;
    animation: fade-in-left 0.9s cubic-bezier(0.39, 0.575, 0.565, 1) 0.2s both;
  }
  .fade-in-bottom {
    -webkit-animation: fade-in-bottom 2s cubic-bezier(0.77, 0, 0.175, 1) 0.1s
      both;
    animation: fade-in-bottom 2s cubic-bezier(0.77, 0, 0.175, 1) 0.1s both;
  }
  .fade-in-top {
    -webkit-animation: fade-in-top 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s
      both;
    animation: fade-in-top 1s cubic-bezier(0.645, 0.045, 0.355, 1) 0s both;
  }

  .doink {
    animation: bounce 8s infinite;
  }

  @media (min-width: 768px) {
    .vertical-text {
      writing-mode: vertical-rl;
      text-orientation: mixed;
    }
  }

  .diagonal-box {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
    transform: translateY(-150px) skewY(-4deg);
  }

  .diagonal-box2 {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
    transform: translateY(20px) skewY(4deg);
  }

  .diagonal-box3 {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
    transform: translateY(255px) skewY(-2deg);
  }

  .gradientBg {
    background-image: linear-gradient(
      45deg,
      rgba(3, 41, 120, 1) 0%,
      rgba(0, 212, 255, 1) 100%
    );
  }

  .content {
    min-width: 50em;
    min-height: 8em;
    max-width: 50em;
    margin: 0 auto;
  }

  .content2 {
    min-width: 50em;
    min-height: 24em;
    max-width: 50em;
    margin: 0 auto;
  }

  .blur {
    filter: blur(4px);
    transition: 0.4s;
    /* content: "Certificate Number: #000-0000-0000-0"; */
  }
  .blur:hover {
    filter: blur(0);
    transition: 0.4s;
  }

  @keyframes bounce {
    0%,
    100% {
      transform: translateY(0%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    25% {
      transform: translateY(-5%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
    75% {
      transform: translateY(5%);
      animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    }
  }

  @-webkit-keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-left {
    0% {
      -webkit-transform: translateX(-50px);
      transform: translateX(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
      transform: translateX(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-bottom {
    0% {
      -webkit-transform: translateY(50px);
      transform: translateY(50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes fade-in-top {
    0% {
      -webkit-transform: translateY(-50px);
      transform: translateY(-50px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
</style>
